import { SuperClusterAlgorithm } from "@googlemaps/markerclusterer";

import { STATIC_SERVER } from "../../constants";
import { ILocation } from "../../types";

export const getCenter = (locationEntries: ILocation[]) => {
  const bound = new google.maps.LatLngBounds();
  for (let i = 0; i < locationEntries.length; i++) {
    bound.extend(
      new google.maps.LatLng(locationEntries[i].lat, locationEntries[i].lng)
    );
  }

  const boundCenter = bound.getCenter();
  return { bound, boundCenter };
};

export const getInfoWindow = (position: ILocation) => {
  return `
    <div style="font-size: 12px"> 
        <div>  <a href="/${position.account_id}/vehicles/${
    position.vehicle_id
  }">${position.vehicle_name}</a></div>
        <div> <a href="/${position.account_id}/contacts/${
    position.driver_id
  }"> ${position.driver_name || "-"}</a></div>
        <div>  ${position.speed} KM/h</div>

    </div>
`;
};

function setGlyphImgSrc(glyphImg: HTMLImageElement) {
  glyphImg.src = STATIC_SERVER + "/icons/cars/car-glyph.svg";
}

function getGlyph() {
  const glyphImg = document.createElement("img");
  setGlyphImgSrc(glyphImg);
  return glyphImg;
}

export function getPin(location: ILocation) {
  // @ts-ignore
  return new google.maps.marker.PinElement({
    scale: 1,
    background: location.ignition === 1 ? "#15ad5b" : "#cbc6b8",
    borderColor: "#3d3d4d",
    glyph: getGlyph(),
  });
}

export class BoundingSuperClusterAlgorithm extends SuperClusterAlgorithm {
  cluster({ map }: any) {
    const bounds = map.getBounds().toJSON();
    const boundingBox = [bounds.west, bounds.south, bounds.east, bounds.north];

    return this.superCluster
      .getClusters(boundingBox, Math.round(map.getZoom()))
      .map(this.transformCluster.bind(this));
  }
}
