import React from "react";
import { createRoot } from 'react-dom/client';

import App from "./App";

import {getCookie} from "./utils/http";

if (!getCookie("token")) {
    window.location.replace("https://auth.inmatics.io");
}

const root = createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
