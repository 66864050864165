import { Layout } from "antd";
import axios from "axios";
import React, { useLayoutEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Outlet } from "react-router-dom";
import Sidebar from "src/organisms/Sidebar/Sidebar";
import { User } from "src/types";

const { Content } = Layout;

interface AccountProps {
  user: User;
}
export default function Account(props: AccountProps) {
  const { user } = props;
  const [axiosConfigured, setAxiosConfigured] = useState(false);

  let accountID = user.roles[0].account_id.toString();

  useLayoutEffect(() => {
    axios.defaults.headers.common["Account-Token"] = accountID;
    //If user does not have a role in these account, redirect home. Doing hard redirect because React Router is not reloading right.
    if (
      !user.roles?.map((x) => x.account_id).includes(Number(accountID)) &&
      accountID !== "me"
    ) {
      window.location.replace("/");
    }
    setAxiosConfigured(true);
  }, [accountID, user.roles]);

  if (!axiosConfigured) {
    return null;
  }

  return (
    <HelmetProvider>
      <Layout>
        {/*<Header user={user} />*/}
        <Layout className="body">
          <Sidebar />
          <Content className="main-content">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </HelmetProvider>
  );
}
