import "antd/dist/reset.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Account from "./routes/Account";
import Dashboard from "./routes/Dashboard/Dashboard";
import Map from "./routes/Map/Map";
import { useSimpleSWRFetch } from "./utils/rest";

export default function App() {
  const { data } = useSimpleSWRFetch("auth/user");

  if (!data) {
    return null;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Account user={data} />}>
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="map" element={<Map />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
