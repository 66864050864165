import { MarkerClusterer } from "@googlemaps/markerclusterer";
import React, { useEffect, useRef, useState } from "react";
import { ILocation } from "src/types";

import { getCenter, getInfoWindow, getPin } from "./utils";

export function MapWithCluster({ entries }: { entries: ILocation[] }) {
  const mapDivRef = useRef<any>();

  const markersByVehicleID = useRef<{
    [vehicleID: string]: google.maps.marker.AdvancedMarkerView;
  }>({});

  const infoWindows = useRef<google.maps.InfoWindow[]>([]);

  const [map, setMap] = useState<google.maps.Map>();
  const cluster = useRef<MarkerClusterer>();

  //Init map
  useEffect(() => {
    if (mapDivRef.current && !map) {
      const { bound, boundCenter } = getCenter(entries);

      const map = new google.maps.Map(mapDivRef.current, {
        center: boundCenter.toJSON(),
        mapId: "63d68809f95fbc19",
      });

      map.fitBounds(bound);
      setMap(map);
    }
    // It should only set the position for the first time.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  //Init cluster
  useEffect(() => {
    if (!cluster.current && map) {
      cluster.current = new MarkerClusterer({
        map,
      });
    }
  }, [map]);

  //Initialize and update markers
  useEffect(() => {
    if (map && cluster.current) {
      entries.forEach((location) => {
        if (markersByVehicleID.current[location.vehicle_id]) {
          markersByVehicleID.current[location.vehicle_id].content =
            getPin(location).element;
          markersByVehicleID.current[location.vehicle_id].position = location;
        } else {
          const advancedMarkerView = new google.maps.marker.AdvancedMarkerView({
            position: location,
            title: location.vehicle_name,
            content: getPin(location).element,
          });
          markersByVehicleID.current[location.vehicle_id] = advancedMarkerView;

          const infoWindow = new google.maps.InfoWindow({
            disableAutoPan: true,
          });
          infoWindow.setContent(getInfoWindow(location));

          advancedMarkerView.addListener("click", (e: any) => {
            map.panTo(e.latLng?.toJSON());

            infoWindow.open(
              { map: advancedMarkerView.map, shouldFocus: false },
              advancedMarkerView
            );
          });
          infoWindow.open(
            { map: advancedMarkerView.map, shouldFocus: false },
            advancedMarkerView
          );

          infoWindows.current.push(infoWindow);
          cluster.current?.addMarker(advancedMarkerView);
        }
      });
    }
  }, [map, entries, cluster]);

  return (
    <>
      <div style={{ height: "90vh" }} ref={mapDivRef} id="map" />
    </>
  );
}
