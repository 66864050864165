import { PushpinOutlined, WindowsOutlined } from "@ant-design/icons";
import { Layout, Menu, MenuProps } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps["items"] = [
  getItem(<Link to={`/`}>{"Inicio"}</Link>, "dashboard", <WindowsOutlined />),
  getItem(<Link to={`/map`}>{"Mapa"}</Link>, "map", <PushpinOutlined />),
];

export default function Sidebar() {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Sider
      className={"sidebar"}
      collapsed={collapsed}
      collapsible
      onCollapse={(collapsed: boolean) => setCollapsed(collapsed)}
      style={{ height: "100vh" }}
      theme={"dark"}
      width={220}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 10,
        }}
      >
        <a href={"/"} style={{ display: "flex" }}>
          <img
            className="logo"
            src={`https://static.inmatics.io/logos/iso_Inmatics.svg`}
            alt=""
            width="32"
          />
        </a>
      </div>
      <Menu
        theme={"dark"}
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        mode="inline"
        items={items}
      />
    </Sider>
  );
}
