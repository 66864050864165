import React from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import DashboardLastLocations from "./DashboardLastLocations";
import DashboardVehicleIgnition from "./DashboardVehicleIgnition";

const Wrapper = styled.div`
  min-height: 100vh;

  .tile-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    align-items: start;
    height: 100%;
  }

  @media (min-width: 1200px) {
    .tile-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 100%;
    }
  }

  @media (min-width: 1400px) {
    .tile-wrapper {
      display: grid;
      grid-template-columns: 3fr 4fr 3fr;
      height: 100%;
    }
  }
`;
export default function Dashboard() {
  return (
    <Wrapper>
      <Helmet>
        <title>Centro de control | Inmatics</title>
      </Helmet>

      <div className="tile-wrapper p-1">
        <div style={{ gridColumn: "1/2" }}>
          <DashboardVehicleIgnition />
        </div>
        <div style={{ gridColumn: "2/2" }}>
          <DashboardLastLocations />
        </div>
      </div>
    </Wrapper>
  );
}
