import { ThunderboltOutlined } from "@ant-design/icons";
import { Card, Statistic } from "antd";
import React from "react";

export default function DashboardVehicleIgnition() {
  const title = (
    <div>
      <ThunderboltOutlined className={"muted mr-2"} />
      Vehículos encendidos
    </div>
  );

  return (
    <Card title={title} bodyStyle={{ maxHeight: 300, overflowY: "auto" }}>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Statistic
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          title="Vehículos Encendidos"
          value={4}
          valueStyle={{ color: "#3f8600" }}
        />
      </div>
    </Card>
  );
}
