import { PushpinOutlined } from "@ant-design/icons";
import { Card } from "antd";
import React from "react";

export default function DashboardLastLocations() {
  return (
    <Card
      title={
        <div>
          <PushpinOutlined className={"muted mr-2"} />
          {"Últimas ubicaciones"}
        </div>
      }
      className={"mb-3"}
    >
      sdf
    </Card>
  );
}
