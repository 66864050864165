import axios, { AxiosRequestConfig } from "axios";
import useSWR from "swr";

let _servers: { v1: string; v2: string; v3: string };

if (process.env.NODE_ENV === "production") {
  _servers = {
    v1: "https://api.inmatics.io/",
    v2: "https://v2api.inmatics.io/",
    v3: "https://v3api.inmatics.io/",
  };
} else {
  _servers = {
    v1: "http://localapi.inmatics.io:3030",
    v2: "http://localapi.inmatics.io:8080/",
    v3: "http://localapi.inmatics.io:4321/",
  };
}

export interface IRest {
  get(url: string, options?: any): Promise<any>;

  patch(url: string, body: any, options?: any): Promise<any>;

  post(url: string, body: any, options?: any): Promise<any>;

  delete(url: string, body?: any, options?: any): Promise<any>;
}

// Closure implementation
export function getApi(): { [key: string]: IRest } {
  function createVersion(version: string, host: string): IRest {
    return {
      async get(url: string, options?: any): Promise<any> {
        return callApi(version, "GET", host + url, null, options);
      },

      async patch(url: string, body: any, options?: any): Promise<any> {
        return callApi(version, "PATCH", host + url, body, options);
      },

      async delete(url: string, body: any, options?: any): Promise<any> {
        return callApi(version, "DELETE", host + url, body, options);
      },

      async post(url: string, body?: any, options?: any): Promise<any> {
        return callApi(version, "POST", host + url, body, options);
      },
    };
  }

  async function callApi(
    version: string,
    method: string,
    url: string,
    body: any,
    options?: any
  ): Promise<any> {
    // Perform the actual API call here, e.g., using axios
    // You can customize the call based on the version and method

    const requestOptions: AxiosRequestConfig = {
      method: method as any,
      url: url,
      data: body,
      withCredentials: true,
      ...options,
    };

    return axios(requestOptions);
  }

  return {
    v1: createVersion("v1", _servers.v1),
    v2: createVersion("v2", _servers.v2),
    v3: createVersion("v3", _servers.v3),
  };
}

export const useSWRFetch = (url: string, apiVersion: string = "v3") => {
  const fetcher = async () => {
    let v3 = getApi().v3;
    const axiosResponse = await v3.get(url, {});

    return {
      data: axiosResponse.data,
      count: axiosResponse.headers["x-total-count"],
    };
  };

  return useSWR(url, fetcher, {
    initialData: null,
    revalidateOnMount: true,
  });
};

export const useSimpleSWRFetch = (url: string) => {
  const fetcher = async () => {
    return (await getApi().v3.get(url, {})).data;
  };

  return useSWR(url, fetcher, {
    initialData: null,
    revalidateOnMount: true,
  });
};
