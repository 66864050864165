import { Status, Wrapper } from "@googlemaps/react-wrapper";
import React from "react";
import { Helmet } from "react-helmet-async";
import { GOOGLE_MAPS_KEY } from "src/constants";
import { useSimpleSWRFetch } from "src/utils/rest";

import { MapWithCluster } from "../../organisms/Maps/MapWithCluster";

const renderStatus = (status: Status) => {
  if (status === "LOADING") return <h3>{status} ..</h3>;
  if (status === "FAILURE") return <h3>{status} ...</h3>;
  return <h3>{status} ...</h3>;
};

export default function Map() {
  const { error, data } = useSimpleSWRFetch("locations/last");

  if (error || !data) {
    return null;
  }

  return (
    <div>
      <Helmet>
        <title>Mapa de flota | Inmatics</title>
      </Helmet>

      <div>
        <Wrapper
          apiKey={GOOGLE_MAPS_KEY}
          render={renderStatus}
          version={"beta"}
          libraries={["marker"]}
        >
          <MapWithCluster entries={data} />
        </Wrapper>
      </div>
    </div>
  );
}
